<script>
// Extensiones
import View from '@/components/View'

// Mixins
import LoadSections from '@/mixins/load-sections'

export default {
  name: 'Services',

  metaInfo: { title: 'Services'},

  extends: View,


  mixins: [
    LoadSections([
      'services-header',
      'services-projects',
      'services-fresado',
      'services-microfresado',
      'services-advant',
    ]),
  ],

  props: {
    id: {
      type: String,
      default: 'service',
    },
  },
}
</script>